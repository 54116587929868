/* Color variables */
html {
  --background-color: #f1f1f1;
  --dark-gray: #333;
  --blue-info: #17a2b8;
}

/* Fonts */
html {
  font-family: 'Roboto', sans-serif;
}

/* Add some padding to bottom for footer */
body {
  padding-bottom: 3rem;
}

/* Fix bootstrap footer overlapping stuff */
footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2rem;
}

.padding-top-50px {
  padding-top: 50px;
}
